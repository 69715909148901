<template>
<div>
  <b-card no-body>
    <div class="m-2">

      <!-- Table Top -->
      <b-row class="pb-1">
        <b-col cols="8">
          <h4>Danh sách {{options.filter(i => i.value===this.selected)[0].text}}</h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button
            variant="primary"
            @click="New()" class="btn-sm">
            <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-text-top"
                /> Thêm {{this.options.filter(i => i.value==this.selected)[0].text}}
          </b-button>
        </b-col>
        <!-- Per Page -->
      </b-row>

      <b-row>
        <b-col cols="12">
          <vue-good-table :columns="fields" :rows="items" :line-numbers="true" :pagination-options="{enabled: true}"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                     <span v-if="selected ==='user-mgmt'">
                       <b-button  
                          variant="primary" class="btn-icon" size="sm" style="margin-left:6px" @click="Permission(props.row,props.row.id,3)">
                          <feather-icon icon="ApertureIcon"/>
                        </b-button> 

                      <b-button  
                          variant="primary" class="btn-icon" size="sm" style="margin-left:6px" @click="Permission(props.row,props.row.id,1)">
                          <feather-icon icon="UserCheckIcon"/>
                        </b-button> 
                      <b-button 
                          variant="primary" class="btn-icon" size="sm" style="margin-left:6px" @click="ChangePas(props.row,props.row.id)">
                          <feather-icon icon="LockIcon"/>
                        </b-button> 
                      </span>
                      <span v-else-if="selected ==='group-mgmt'">
                      <b-button  
                          variant="primary" class="btn-icon" size="sm" style="margin-left:6px" @click="Permission(props.row,props.row.id,2)">
                          <feather-icon icon="UserCheckIcon"/>
                        </b-button> 
                      </span>
                     <b-button
                        variant="primary" class="btn-icon" size="sm" style="margin-left:6px" @click="Edit(props.row,props.row.id)">
                        <feather-icon icon="Edit2Icon"/>
                      </b-button>
                      <b-button
                        variant="danger"
                        class="btn-icon"
                        style="margin-left:6px"
                        size="sm"
                        @click="Delete(props.row,props.row.id)">
                        <feather-icon icon="TrashIcon"/>
                      </b-button>
                </span>
              </span>

              <!-- Column: Common -->
     
               <span v-else-if="allFields.filter(i=>i.field==props.column.field)[0].select2 && allFields.filter(i=>i.field==props.column.field)[0].select2.filter(i => i.id == props.row[props.column.field] ).length>0">
                {{ allFields.filter(i=>i.field==props.column.field)[0].select2.filter(i => i.id == props.row[props.column.field])[0].text }}      
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field]}}
              </span>
              
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.pageSize"
                    :options="['10','50','100','500']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ items.length }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="serverParams.pageSize"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>

      </b-row>
    </div>   
  </b-card>

  <b-modal size="lg" id="modal-edit" :title="getTitle()" @ok="handleOk">
    <validation-observer ref="modalEdit">
      <b-row>
        <b-col cols="12" :md="i.cols != null?i.cols:6" v-for="i in allFields.filter(i => i.field!=='action')" :key="i.field">
          <validation-provider :name="'\''+i.label+'\''" #default="{ errors }" :rules="( !i.autoIncrement && (i.field=='name' || i.field=='id' || i.required))?'required':''" v-if="i.select2==null">        
            <b-form-group :label="i.label" v-if="i.date_time==true" >
              <b-form-datepicker
                    :state="errors.length > 0 ? false:null"
                      :date-format-options="{ year: 'numeric', month: '2-digit',day: '2-digit', }" locale="vi-VN"
                      v-model="currentItem[i.field]"
                    />
              </b-form-group>
            <b-form-group :label="i.label" v-else>
              <b-form-input  :disabled="(i.field==='id' || i.disabled) && editMode || i.autoIncrement" :state="errors.length > 0 ? false:null" v-model="currentItem[i.field]" :placeholder="i.autoIncrement?'Hệ thống tự sinh':i.label"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>        
          </validation-provider>
          <validation-provider :name="'\''+i.label+'\''" #default="{ errors }" :rules="(i.field=='name' || i.field=='id' || i.required)?'required':''" v-else>
            <b-form-group :label="i.label">
              <Select2 :state="errors.length > 0 ? false:null" :placeholder="i.label" v-model="currentItem[i.field]" :options="i.select2"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>

  <b-modal id="modal-permission" scrollable body-class="p-0" title="Gán nhóm quyền cho người dùng" @ok="handPermission">
    <b-table responsive="sm" :items="permisItems" :fields="perFields">
      <template #cell(name)="row">
           {{perFields}}
        {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(C)="row">
        <b-form-checkbox v-model="row.item.C"/>
      </template>
      <template #cell(R)="row">
        <b-form-checkbox v-model="row.item.R"/>
      </template>
      <template #cell(U)="row">
        <b-form-checkbox v-model="row.item.U"/>
      </template>
      <template #cell(D)="row">
        <b-form-checkbox v-model="row.item.D"/>
      </template>
    </b-table>
  </b-modal>

  <b-modal id="modal-pass" scrollable title="Đổi mật khẩu" @ok="handChangePass">
    <validation-observer ref="simpleRules">
      <validation-provider #default="{ errors }" rules="required">
        <b-form-group label="Mật khẩu hiện tại" >
          <b-form-input type="password" :state="errors.length > 0 ? false:null" v-model="changepass.old" placeholder="Mật khẩu hiện tại"/>
        </b-form-group>
      </validation-provider>
      <validation-provider #default="{ errors }" rules="required">
        <b-form-group label="Mật khẩu mới" >
          <b-form-input type="password" :state="errors.length > 0 ? false:null" v-model="changepass.new" placeholder="Mật khẩu mới"/>
        </b-form-group>
      </validation-provider>
      <validation-provider #default="{ errors }" rules="required">
        <b-form-group label="Nhập lại" >
          <b-form-input type="password" :state="errors.length > 0 ? false:null" v-model="changepass.retype" placeholder="Nhập lại"/>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>

  <b-modal id="modal-gpermission" size="lg" scrollable body-class="p-0" title="Phân quyền chức năng" @ok="handGPermission">
    <b-table responsive="sm" :items="permisItems" :fields="perGroupFields">
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(C)="row">
        <b-form-checkbox v-model="row.item.C"/>
      </template>
      <template #cell(R)="row">
        <b-form-checkbox v-model="row.item.R"/>
      </template>
      <template #cell(U)="row">
        <b-form-checkbox v-model="row.item.U"/>
      </template>
      <template #cell(D)="row">
        <b-form-checkbox v-model="row.item.D"/>
      </template>
    </b-table>
  </b-modal>


  <b-modal id="modal-dql-permission" scrollable body-class="p-0" title="Gán quyền đội quản lý cho người dùng" @ok="handDQLPermission">
    <b-table responsive="sm" :items="permisItems" :fields="perDFields">
      <template #cell(name)="row">
        {{row}}
        {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(C)="row">
        <b-form-checkbox v-model="row.item.C"/>
      </template>
      <template #cell(R)="row">
        <b-form-checkbox v-model="row.item.R"/>
      </template>
      <template #cell(U)="row">
        <b-form-checkbox v-model="row.item.U"/>
      </template>
      <template #cell(D)="row">
        <b-form-checkbox v-model="row.item.D"/>
      </template>
    </b-table>
  </b-modal>


</div>
</template>

<script>


import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { BFormDatepicker, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormCheckbox,BFormGroup,VBTooltip,BFormSelect } from 'bootstrap-vue'
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-good-table.scss';
import Select2 from 'v-select2-component';
import moment from 'moment';
import { stringToDate } from "@/@core/utils/utils";


export default {
  
 components: {
    BFormDatepicker,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,vSelect,
    FeatherIcon,BModal,BFormGroup,BFormCheckbox,ValidationProvider, ValidationObserver,BFormSelect,VueGoodTable,Select2
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted(){
    this.loadMasterData();
    
  },
  watch: {
    $route(to, from) {
      this.loadMasterData();
    }
  },
  methods: {
    loadMasterData(){
      this.selected = this.$route.meta.type;
      this.allFields = this.options.filter(i=>i.value==this.selected)[0].fields;
      this.fields = JSON.parse(JSON.stringify(this.options.filter(i=>i.value==this.selected)[0].fields));
      console.log(this.fields);
      this.fields.forEach (i=>{
        delete i.select2;delete i.cols;delete i.autoIncrement; delete i.required; 
      })
      this.loadItems();
    },
    getTitle(){
      if (this.editMode){
        return 'Cập nhật '+this.options.filter(i => i.value===this.selected)[0].text
      }else{
        return 'Thêm mới '+this.options.filter(i => i.value===this.selected)[0].text
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({pageSize: params.currentPerPage});
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    fetchGPermission: function() {
      this.permisItems=[];
      useJwt.get(`groupPermission/${this.currentItem.ID}`,{})
        .then(response =>{
          this.permisItems = response.data.result;
          this.permisItems.forEach(function(i,v){
            i.C = i.C==1?true:false;
            i.R = i.R==1?true:false;
            i.U = i.U==1?true:false;
            i.D = i.D==1?true:false;
          })
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách quyền theo nhóm '+this.currentItem.ROLE_NAME,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    
     fetchPermission: function() {
      this.permisItems=[];
      this.updateParams({
        columnFilters: {},
      });
      useJwt.get(`userGroup/${this.currentItem.ID}`,{})
        .then(response =>{
          this.permisItems = response.data.result;
          this.permisItems.forEach(function(i,v){
            i.C = (i.C==1)?true:false;
          })
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách quyền theo nhóm '+this.currentItem.ROLE_NAME,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
     fetchDQLPermission: function() {
      this.permisItems=[];     
      let param = {
        columnFilters:{
          USER_ID:this.currentItem.ID
        }
      }
      this.updateParams(param);
      useJwt.post('getAdminData/role-dql',this.serverParams )
        .then(response =>{
          this.permisItems = response.data.result;
          console.log(this.permisItems);
          this.permisItems.forEach(function(i,v){
            i.C = (i.C==1)?true:false;
          })
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách quyền theo nhóm '+this.currentItem.NAME,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    loadItems() {
      useJwt.post('getAdminData/'+this.selected,this.serverParams).then(response =>{
        if (response.data.extra!=null){
          for (var key of Object.keys(response.data.extra)) {
            this.options.filter(i=>i.value==this.selected)[0].fields.filter(i=>i.field === key)[0].select2 = response.data.extra[key];
          }
        }
        this.items = response.data.result;
        console.log(this.items);
        this.totalRecords = response.data.totalRecords;
        this.isLoading = false;
      })
    },
    Edit(item,index,button) {
      this.currentItem = item;
      this.editMode=true;
      this.modal_title= "Cập nhật "+this.options.filter(i => i.value===this.selected)[0].text
      this.$bvModal.show('modal-edit')
    },
    New(){
      this.currentItem={}
      this.editMode=false;
      this.modal_title= "Thêm mới "+this.options.filter(i => i.value===this.selected)[0].text
      this.$bvModal.show('modal-edit')
    },

    async Delete(item, index, button) {
      {{this.selected}} {{this.currentItem.ID}}
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm('Vui lòng xác nhận bạn muốn xoá dữ liệu '+this.selected, {title: 'Please Confirm',size: 'sm',})
      if (res){

        useJwt.delete(`adminData/${this.selected}/${this.currentItem.ID}`, {})
        .then(response =>{
          this.loadItems();
          this.$toast({component: ToastificationContent,props: {title: 'Xoá '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi xoá '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },


    Permission(item, index, button){
      this.currentItem = item;
      if(button=="1"){
           this.fetchPermission();
           this.$bvModal.show('modal-permission')
      }else if(button=="2"){
          this.fetchGPermission();
         this.$bvModal.show('modal-gpermission')
      }else{
         this.fetchDQLPermission();
         this.$bvModal.show('modal-dql-permission')
         
      } 
    },
    
    
    handDQLPermission(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.permisItems.forEach(function(i,v){
        i.C = i.C==null?false:i.C;
        i.R = i.R==null?false:i.R;
        i.U = i.U==null?false:i.U;
        i.D = i.D==null?false:i.D;
      });
      let permissions = [];
      this.permisItems.filter(i => i.C || i.R || i.U || i.D).forEach(i=>{
        permissions.push({menu_id:i.ID,c:i.C,r:i.R,u:i.U,d:i.D})
      })
      useJwt.put(`groupPermission/${this.currentItem.ID}`, {
          id: this.currentItem.ID,
          permissions: permissions
        })
        .then(response =>{
          this.fetchGPermission();
          this.$bvModal.hide('modal-gpermission')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật quyền cho nhóm',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },


    async handPermission(bvModalEvt) {
      bvModalEvt.preventDefault();  
      var groups = [];
      this.permisItems.filter(i => i.C).forEach(e => groups.push(e.id));

      useJwt.put(`userGroup/${this.currentItem.ID}`, {
          id: this.currentItem.ID,
          groups: groups
        })
        .then(response =>{
          this.fetchPermission();
          this.$bvModal.hide('modal-permission')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật quyền cho nhóm',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },
     handGPermission(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.permisItems.forEach(function(i,v){
        i.C = i.C==null?false:i.C;
        i.R = i.R==null?false:i.R;
        i.U = i.U==null?false:i.U;
        i.D = i.D==null?false:i.D;
      });
      let permissions = [];
      this.permisItems.filter(i => i.C || i.R || i.U || i.D).forEach(i=>{
        permissions.push({menu_id:i.ID,c:i.C,r:i.R,u:i.U,d:i.D})
      })
      useJwt.put(`groupPermission/${this.currentItem.ID}`, {
          id: this.currentItem.ID,
          permissions: permissions
        })
        .then(response =>{
          this.fetchGPermission();
          this.$bvModal.hide('modal-gpermission')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật quyền cho nhóm',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },
    ChangePas(item,index,button){
      this.currentItem = item;
      this.$bvModal.show('modal-pass')
    },

    async handChangePass(bvModalEvt){
      bvModalEvt.preventDefault();
      var validate = await this.$refs.simpleRules.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      useJwt.post('changepass', {
          id:this.currentItem.ID,"old_password":this.changepass.old,"password":this.changepass.new
        })
        .then(response =>{
          //console.log('.............');
          this.$bvModal.hide('modal-pass')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
          setTimeout(function(){
            localStorage.clear();
            router.push({name:'auth-login'});
          },3000);
        })
        .catch(error => {
          console.log(error.response);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật người dùng ['+error.response.data.message+']',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },

    async handleOk(bvModalEvt){
      bvModalEvt.preventDefault()
      var validate = await this.$refs.modalEdit.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }

      if (!this.editMode){
        useJwt.post('adminData/'+this.selected, this.currentItem)
        .then(response =>{
          this.loadItems();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Thêm mới '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi thêm mới '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }else{
        useJwt.put('adminData/'+this.selected,this.currentItem)
        .then(response =>{
          this.loadItems();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật '+this.options.filter(i => i.value===this.selected)[0].text+ ' ['+error.response.data.result+']!',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      items: [],
      editMode:false,
      totalItem:0,
      permisItems: [],
      searchQuery:"",
      searchTerm: '',
      currentItem:{},
      selected: 'user-mgmt',
      changepass:{},
      options: [
        {
          value:'user-mgmt',
          text:'người dùng',
          fields: [
            {
              field: 'ID',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên đăng nhập',
              required:true,
            },
            {
              field: 'MA_NV',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Mã nhân viên',
              required:true,
            },
            {
              field: 'FULL_NAME',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên nhân viên',
              required:true,
            },
            {
              field: 'MOBILE',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Số điện thoại',
              required:true,
            },
            {
              field: 'EMAIL',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Email',
              required:true,
            },
             {
              field: 'GENDER_ID',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Giới tính',
              required:true,
              hidden: true,
              select2:[],
            },
             {
              field: 'POSITION_ID',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Vị trí',
              required:true,
              hidden: true,
              select2:[],
            },
            
             {
              field: 'STATUS_ID',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Trạng thái',
              select2:[],
              required:true,
            },
            { field: 'action', label: 'Actions',width: '210px', }
          ],
        },
        
        {
          value:'group-mgmt',
          text:'quyền',
          fields: [
            {
              field: 'ID',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Mã nhóm',
              required:true,
            },
            {
              field: 'ROLE_NAME',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên nhóm',
              required:true,
            },
            {
              field: 'DESCRIPTION',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Mô tả',
              cols:12,
              required:true,
            },
            { field: 'action', label: 'Actions',width: '130px', }
          ],
        },
      ],
      perGroupFields: [
        {
          key: 'FUNCTION_NAME',
          sortable: true,
          label: 'Chức năng'
        },
        {
          key: 'C',
          sortable: true,
          label: 'Đọc'
        },
        {
          key: 'R',
          sortable: true,
          label: 'Xem'
        },
        {
          key: 'U',
          sortable: true,
          label: 'Cập nhật'
        },
        {
          key: 'D',
          sortable: true,
          label: 'Xoá'
        },
      ],
       perFields: [
        {
          key: 'ROLE_NAME',
          sortable: true,
          label: 'Chức năng'
        },
        {
          key: 'C',
          sortable: true,
          label: 'Thuộc nhóm'
        },
       ],

       perDFields: [
        {
          key: 'FULL_NAME',
          sortable: true,
          label: 'Đội quản lý'
        },
        {
          key: 'C',
          sortable: true,
          label: 'Quyền'
        },

      ],
      fields: [],
      allFields: [],
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1, 
        pageSize: 50
      },
      required
    }
  },
}
</script>
<style>
.select2-selection--single{
    border: 1px solid #2B938C;
    border-radius: 1.5rem;
}
.b-form-datepicker.form-control-sm .form-control-sm, .b-form-timepicker.form-control-sm .form-control-sm {
    line-height: 2;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMkI5MzhDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.75em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>